import React, { Component } from 'react';
import { ScaleLoader } from 'react-spinners';
import classNames from 'classnames';
import { OpenTokSDK } from 'opentok-accelerator-core';
import { isUndefined, isEmpty } from 'lodash';
import * as Sentry from '@sentry/browser'
import SvgIcon from '../SvgIcon';
// import roundFullscreenExit from 'assets/icons/visio/round-fullscreen-exit.svg'
import roundFullscreen from 'assets/icons/visio/round-fullscreen.svg'
import roundMicOff from 'assets/icons/visio/round-mic-off.svg'
import roundMic from 'assets/icons/visio/round-mic.svg'
import roundPhoneMissed from 'assets/icons/visio/round-phone-missed.svg'
// import roundPower from 'assets/icons/visio/round-power.svg'
import roundVideocamOff from 'assets/icons/visio/round-videocam-off.svg'
import roundVideocam from 'assets/icons/visio/round-videocam.svg'

import './styles.sass';
import { Button } from 'components'


const callProperties = {
  insertMode: 'append',
  width: '100%',
  height: '100%',
  fitMode: 'contain',
  showControls: false,
  insertDefaultUI: true,
  style: {
    buttonDisplayMode: 'auto',
    videoDisabledDisplayMode: 'on',
  }
};



/**
 * Build classes for container elements based on state
 * @param {Object} state
 * @returns {Object}
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  // const sharingScreen = meta ? !!meta.publisher.screen : false;
  // const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;

  return {
    controlClass: classNames('VisioBox-control-container', { 'hidden': !active }),
    localAudioClass: classNames('ots-video-control circle audio', { 'toggled': !localAudioEnabled }),
    localVideoClass: classNames('ots-video-control circle video', { 'toggled': !localVideoEnabled }),
    fullscreenClass: classNames('ots-video-control circle fullscreen', { 'hidden': !activeCameraSubscribers }),
    powerOffClass: classNames('ots-video-control circle power-off'),
    cameraPublisherClass: classNames('video-container', {
      'hidden': !active,
      'small': !!activeCameraSubscribers, // || sharingScreen,
      // 'left': sharingScreen || viewingSharedScreen
    }),
    // screenPublisherClass: classNames('video-container', { 'hidden': !sharingScreen }),
    cameraSubscriberClass: classNames('video-container', `active-${activeCameraSubscribers}`, {
      'hidden': !activeCameraSubscribers ,
      // 'small': viewingSharedScreen || sharingScreen,
    }),
    // screenSubscriberClass: classNames('video-container', { 'hidden': !viewingSharedScreen }),
  };
};

const waitingRoomMask = () =>
  <div className="VisioBox-mask">
    <ScaleLoader height={50} width={10} margin="1em" radius={5} color={'#020779'} />
    <div className="message with-spinner" style={{ margin: '1em auto' }}>En attente du médecin...</div>
  </div>;

const connectingMask = () =>
  <div className="VisioBox-mask">
    <ScaleLoader height={50} width={10} margin="1em" radius={5} color={'#020779'} />
    <div className="message with-spinner" style={{ margin: '1em auto' }}>Connexion en cours...</div>
  </div>;

const startCallMask = (start) =>
  <div className="VisioBox-mask">
    <div className="message" style={{ margin: '1em auto' }}>Vous êtes désormais connecté</div>
    <Button
      classname="btn-block w-auto"
      onClick={start}
    >
      Cliquez pour démarrer la consultation par vidéo
    </Button>
  </div>;

const errorMask = retry =>
  <div className="VisioBox-mask">
    <div className="message error" style={{ margin: '1em auto' }}>Une erreur est survenue lors de l’initialisation de la communication par vidéo</div>
    <div className="message error button clickable" onClick={retry}>Cliquez pour relancer la consultation par vidéo</div>
  </div>;

const reconnectMask = reconnect =>
  <div className="VisioBox-mask">
    <div className="message" style={{ margin: '1em auto' }}>Votre communication vidéo a été arrêtée</div>
    <Button
      classname="btn-block w-auto"
      onClick={reconnect}
    >
      Cliquez pour relancer la consultation par vidéo
    </Button>
  </div>;


class VisioBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      session: null,
      connected: false,
      error: false,
      active: false,
      stopped: false,
      error: false,
      publishers: null,
      subscribers: null,
      meta: null,
      streamMap: null,
      streams: null,
      localPublisherId: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
    };
    this.startCall = this.startCall.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.reconnect = this.reconnect.bind(this);
    this.retry = this.retry.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.toggleFullScreen = this.toggleFullScreen.bind(this);

    const { apiKey, sessionId, token } = props
    this.otSDK = new OpenTokSDK({ apiKey, sessionId, token } );

    console.log('[OpenTok] Start OpenTok session', apiKey, sessionId, token);
  }

  componentDidMount() {
    this.connect();
  }

  componentWillUnmount() {
    this.disconnect();
  }

  connect() {
    const session = this.otSDK.session;
    this.otSDK.connect()
      .then(() => this.setState({ session, connected: true }))
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err)
        this.setState({ error: true });
      });
  }

  disconnect() {
    this.otSDK.disconnect();
    this.setState({
      ...this.otSDK.state(),
      connected: false,
      active: false,
      stopped: true,
    });
  }

  reconnect() {
    this.setState({ stopped: false });
    this.connect();
  }

  retry() {
    this.setState({ error: false });
    this.connect();
  }

  startCall() {
    const { session, streamMap } = this.state;
    const subscribeToStream = stream => {
      console.log('[OpenTok] Subscribe to stream', stream);

      if (streamMap && streamMap[stream.id]) { return; }
      const type = stream.videoType;
      this.otSDK.subscribe(stream, `${type}SubscriberContainer`, callProperties)
      .then(() => this.setState(this.otSDK.state()))
      .catch(error => {
        console.log(error)
        Sentry.captureException(error)
      })
    };
    // Subscribe to initial streams
    session.streams.forEach(subscribeToStream);

    // Subscribe to new streams and update state when streams are destroyed
    this.otSDK.on({
      'streamCreated' : ({ stream }) => subscribeToStream(stream),
      'streamDestroyed': ({ stream }) => this.setState(this.otSDK.state())
    });

    // Publish local camera stream
    this.otSDK.publish('cameraPublisherContainer', callProperties)
    .then((publisher) => {
      console.log('[OpenTok] Start publishing', publisher);
      this.setState(Object.assign({}, this.otSDK.state(), { localPublisherId: publisher.id }));

      // TODO
    }).catch(error => {
      console.log(error)
      Sentry.captureException(error)
    });

    this.setState({ active: true });
  }

  toggleLocalAudio() {
    const { localPublisherId, publishers, localAudioEnabled } = this.state;
    const enabled = !localAudioEnabled;
    this.otSDK.enablePublisherAudio(enabled);
    this.setState({ localAudioEnabled: enabled });
  }

  toggleLocalVideo() {
    const { localPublisherId, publishers, localVideoEnabled } = this.state;
    const enabled = !localVideoEnabled;
    this.otSDK.enablePublisherVideo(enabled);

    this.setState({ localVideoEnabled: enabled });
  }

  toggleFullScreen() {
    const video = document
      .getElementById('cameraSubscriberContainer')
      .getElementsByTagName('video')[0];

    if (isUndefined(video)) {
      return null;
    }

    if (video.requestFullScreen) {
      video.requestFullScreen();
    } else if (video.webkitRequestFullScreen) {
      video.webkitRequestFullScreen();
    } else if (video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    }
  }

  render() {
    const { connected, active, stopped, error, streams } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      powerOffClass,
      fullscreenClass,
      controlClass,
      cameraPublisherClass,
      // screenPublisherClass,
      cameraSubscriberClass,
      // screenSubscriberClass,
    } = containerClasses(this.state);

    return (
      <div className="VisioBox">
        <div className="VisioBox-main">
          <div id="controls" className={controlClass}>
            {/* TODO: add fullscreen control */}
            <div className={localAudioClass} onClick={this.toggleLocalAudio}>
              <SvgIcon icon={roundMic} classname="enable" />
              <SvgIcon icon={roundMicOff} classname="disable" />
            </div>
            <div className={localVideoClass} onClick={this.toggleLocalVideo}>
              <SvgIcon icon={roundVideocam} classname="enable" />
              <SvgIcon icon={roundVideocamOff} classname="disable" />
            </div>
            <div className={fullscreenClass} onClick={this.toggleFullScreen}>
              <SvgIcon icon={roundFullscreen} />
            </div>
            <div className={powerOffClass} onClick={this.disconnect}>
              <SvgIcon icon={roundPhoneMissed} />
            </div>
          </div>
          <div className="VisioBox-video-container">
            { !connected && !error && !stopped && connectingMask() }
            { !connected && stopped && reconnectMask(this.reconnect) }
            { !connected && error && errorMask(this.retry) }
            { connected && !active && startCallMask(this.startCall)}
            { connected && active && isEmpty(streams) && waitingRoomMask()}
            <div id="cameraPublisherContainer" className={cameraPublisherClass}></div>
            {/* <div id="screenPublisherContainer" className={screenPublisherClass}></div> */}
            <div id="cameraSubscriberContainer" className={cameraSubscriberClass}></div>
            {/* <div id="screenSubscriberContainer" className={screenSubscriberClass}></div> */}
          </div>
          <div id="chat" className="VisioBox-chat-container"></div>
        </div>
      </div>
    );
  }
}

export default VisioBox;
