import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import Media from 'react-media'
import { isUndefined, isNil, get } from 'lodash'
import classnames from 'classnames'
import T from 'types'
import { Button } from '../'
import Block from '../Block'
import { friendlyDisplayDay } from 'helpers/date-helper'
import moment from 'constants/moment'
import {
  UserMedia,
  TeleconsultationContent
} from 'components'
import './styles.sass'
import { SvgIcon } from 'components'
import { getIcon } from 'assets/icons'

interface IProps {
  title: string
  consultation: T.Consultation
  onStartConsultation?: () => void
  profile?: T.Profile
  icon?: string
  summaryOnly?: boolean
}

interface IState {
  clickedStarted: boolean
}

class Teleconsultation extends Component<IProps, IState> {
  refreshInterval?: number

  constructor(props: IProps) {
    super(props)

    this.state = {
      clickedStarted: false,
    }
  }

  componentDidMount() {
    this.refreshInterval = (setTimeout(() => this.forceUpdate(), 60000) as unknown) as number
  }

  componentWillUnmount() {
    clearTimeout(this.refreshInterval)
  }

  public render() {
    const { title, consultation, summaryOnly, icon } = this.props
    const { reportUrl, doctor, fullDoctor, doctorAvatarUrl } = consultation

    return (
      <Block
        icon={icon || 'blocks.doctor'}
        title={title}
      >
        <div className="teleconsultation-container">
          <div className="teleconsultation-doctor-image-container">
            <img src={doctorAvatarUrl} alt={''} className="teleconsultation-doctor-image-img" />
          </div>
          <div className="teleconsultation-doctor">
            <h4 className="">{doctor}</h4>
            <div className="">{get(fullDoctor, 'speciality')}</div>
          </div>
          {this.renderDateStuff()}
          {this.renderActionBlocks()}
        </div>
        {!summaryOnly && (
          <div className="mt-2">
            <TeleconsultationContent
              consultation={consultation}
              show={this.state.clickedStarted}
            />
          </div>
        )}
      </Block>
    )
  }


  private renderDateStuff = () => {
    const { consultation } = this.props
    const { appointmentAt, communicationChannel } = consultation

    const shouldDisplayAppointmentDate = communicationChannel !== 'chat' && appointmentAt
    const stateToBadge = this.stateToBadge(consultation.state)
    return (!isNil(stateToBadge) || shouldDisplayAppointmentDate) && (
      <div className="teleconsultation-appointment">
        <div className="teleconsultation-appointment-date">
          {shouldDisplayAppointmentDate && (
            <>
              <div><SvgIcon icon={getIcon('clinic.calendar')} /></div>
              <div className="font-weight-semi">
                <span className="text-capitalize">
                  {friendlyDisplayDay(moment(appointmentAt))}
                </span> à {moment(appointmentAt).format('LT')}
              </div>
            </>
          )}
          {stateToBadge}
        </div>
      </div>
    )
  }
  private stateToBadge = (state: string | undefined) => {
    switch (state) {
      case "confirmed":
        return <span className="badge badge-pill c-pill-outline-green">Confirmée</span>
      case "started":
        return <span className="badge badge-pill c-pill-fill-green">En cours</span>
      case "finished":
        return <span className="badge badge-pill c-pill-outline-black">Terminée</span>
      case "canceled":
        return <span className="badge badge-pill c-pill-fill-red">Terminée</span>
      case "pending":
      case "paid":
      default:
        return null
    }
  }

  private renderActionBlocks() {
    const { consultation, profile, summaryOnly } = this.props
    const { appointmentAt, state, communicationChannel } = consultation

    if (state === 'finished') {
      return (
        <div className="teleconsultation-actions">
          <NavLink to='/new-consultation' className="new-consultation btn btn-primary">
            {I18n.t(`home.reconsultation.cta`)}
          </NavLink>
        </div>
      )
    }
    if (communicationChannel === "visio") {
      if (!summaryOnly) {
        const durationTillAppointment = moment.duration(moment(appointmentAt).diff(moment()))
        const canJoin = (state === 'started' || state === 'confirmed')
        const showButton = (state === 'started' || durationTillAppointment.as('minutes') <= 15)

        return (
          <div className="teleconsultation-actions">
            {showButton ? (
              <Button
                classname="btn-block"
                onClick={() => this.setState({ clickedStarted: true })}
                disabled={!canJoin || this.state.clickedStarted}
              >
                Rejoindre
              </Button>
            ) : (
              <>
                <div className="mb-1">
                  <UserMedia />
                </div>
                <p className="small">
                  Vous pourrez rejoindre la consultation 15 minutes avant le début du RDV.
                </p>
              </>
            )}
          </div>
        )
      }
    }
    else if (communicationChannel === "phone") {
      return (
        <div className="teleconsultation-actions">
          {I18n.t('home.consultation.phoneInstructions', { phone: get(profile, "phoneNumber") })}
        </div>
      )
    }

    return null
  }
}

export default Teleconsultation
